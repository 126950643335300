import { User, LogOut } from 'react-feather';
import {
    DropdownToggle,
    Dropdown,
    Avatar,
    AvatarInitial,
    Button,
    Image,
} from '@doar/components';
import {
    StyledDropMenu,
    StyledAuthorName,
    StyledAuthorRole,
    StyledDropItem,
    StyledDivider,
    StyledAvatar,
} from './style';
import AuthService from '../../../services/auth.service';
import UserService from '../../../services/user.service';
import Englishtext from '@doar/shared/Text/header/en.json';
import Thaitext from '@doar/shared/Text/header/th.json';
import { useEffect, useState } from 'react';

const ProfileDropdown = () => {
    const [currentUser, setCurrentUser] = useState({} as any);
    const [userAvata, setUserAvata] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setCurrentUser(JSON.parse(await AuthService.getCurrentUser()));
            const response = await AuthService.getProfileImage();
            setUserAvata(response);
            // console.log(currentUser);
        };
        void fetchData();
    }, []);

    const currentLang = () => {
        if (UserService.getCurrentLang() == 'th') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return Thaitext;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return Englishtext;
    };
    return (
        <Dropdown direction="down" className="dropdown-profile">
            <DropdownToggle variant="texted">
                <StyledAvatar size="sm" shape="circle">
                    {userAvata && (
                        <Image src={userAvata} alt="user-profile-header" />
                    )}
                    {userAvata == null && <AvatarInitial>P</AvatarInitial>}
                </StyledAvatar>
            </DropdownToggle>
            <StyledDropMenu>
                <Avatar size="lg" shape="circle">
                    {userAvata && (
                        <Image
                            src={userAvata}
                            alt="user-profile-header-dropdown"
                        />
                    )}
                    {userAvata == null && <AvatarInitial>P</AvatarInitial>}
                </Avatar>
                <StyledAuthorName>
                    {currentUser.firstName} {currentUser.lastName}
                </StyledAuthorName>
                <StyledAuthorRole>{currentUser.username}</StyledAuthorRole>
                <StyledDropItem path="/profile">
                    <User size="24" />
                    {currentLang().viewpro}
                </StyledDropItem>
                <StyledDivider />
                <Button
                    iconButton
                    fullwidth
                    mt={'20px'}
                    color="white"
                    onClick={() => AuthService.logout()}
                    path="/signin"
                >
                    <LogOut size="24" />
                    {currentLang().signout}
                </Button>
            </StyledDropMenu>
        </Dropdown>
    );
};

export default ProfileDropdown;
