import { IbmFontSans } from './ibm-plex-sans';
import { InterUi } from './inter-ui';
import { IonIcons } from './ionicons';
import { Rubik } from './rubik';
import { FontAwesome } from './font-awesome';
import { CryptoFont } from './cryptofont';
import { SFThonburiFont } from './sf-thonburi';

export {
    IbmFontSans,
    InterUi,
    IonIcons,
    Rubik,
    FontAwesome,
    CryptoFont,
    SFThonburiFont,
};
