import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import i18n from 'i18next';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { API_REALTIME_URL, API_URL, IMAGE_URL } from '../constants/api';

const firebaseConfig = {
    apiKey: 'AIzaSyC3QlqMMnYspNRRVafkj-Qqat1FaZw2Gaw',
    authDomain: 'tech-care-system.firebaseapp.com',
    projectId: 'tech-care-system',
    storageBucket: 'tech-care-system.appspot.com',
    messagingSenderId: '291461667501',
    appId: '1:291461667501:web:aebf84c5d2fd962ce10424',
    measurementId: 'G-LX052X1E24',
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

class AuthService {
    //  cookie
    setCookie(name: string, data: string, exp: number) {
        Cookies.set(name, data, { expires: exp });
    }

    getCookie(name: string) {
        return Cookies.get(name) as string;
    }

    removeCookie(name: string) {
        Cookies.remove(name);
    }
    // cookie

    // handle questuinnair
    setQuestionnairePage(data: string) {
        Cookies.set('questionnaire-page', data, { expires: 1 / 24 });
    }

    getQuestionnairePage() {
        if (Cookies.get('questionnaire-page') == undefined) {
            this.setQuestionnairePage('0');
            return '0';
        }
        return Cookies.get('questionnaire-page') as string;
    }

    removeQuestionnairePage() {
        Cookies.remove('questionnaire-page');
    }

    async postQuestionnaire() {
        const jwt = this.getJWT();
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
                data: {},
            }),
        };
        const response = await fetch(API_URL + 'questionnaires', options).then(
            (res) => res.json()
        );
    }

    async getQuestionnaire() {
        //jwt get from cookie or /api/auth/local
        const jwt = this.getJWT();
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(API_URL + `questionnaire`, options).then(
            (res) => res.json()
        );
        // await this.postQuestionnaire();
        return JSON.stringify(response);
    }

    async getQuestionnaireId() {
        return JSON.parse(await this.getQuestionnaire()).id as string;
    }

    // check that user is already done questionnaire after sign up
    // for method in service
    async isDoneQuestionnaire() {
        const data = await JSON.parse(await this.getQuestionnaire());
        // from the fact that therapy is the last part in questionnaire so I assume that if therapy is null so user do not done questionnaire
        if (data.therapy == null) return false;
        return true;
    }

    // for page
    getQuestionnaireStatus() {
        if (this.getCookie('questionnaireStatus') == 'null') {
            return false;
        }
        return true;
    }

    async updateQuestionnaire(questionnaireData: object) {
        //jwt get from cookie or /api/auth/local
        const jwt = this.getJWT();
        const questionnaireId = await this.getQuestionnaireId();
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
                data: {
                    ...questionnaireData,
                },
            }),
        };
        const response = await fetch(
            API_URL + `questionnaires/${questionnaireId}`,
            options
        ).then((res) => res.json());
        // return JSON.stringify(response);
    }

    // check is login
    getJWT() {
        return this.getCookie('jwt');
    }

    async getID() {
        return JSON.parse(await this.getCurrentUser()).id as string;
    }

    isLogin() {
        if (this.getJWT() == undefined) {
            return false;
        }
        return true;
    }

    // get item from local storage
    getItem(itemName: string) {
        const itemStr = localStorage.getItem(itemName);
        if (itemStr) return itemStr;
        return JSON.stringify({ data: false });
    }

    // set item from local storage
    setItem(itemName: string, itemValue: any) {
        localStorage.setItem(itemName, <string>itemValue);
    }

    // handle language
    async setCurrentLang() {
        const lang = JSON.parse(await this.getSetting()).lang as string;

        if (lang == 'th-TH' || lang == 'th') {
            void i18n.changeLanguage('th');
        } else {
            void i18n.changeLanguage('en');
        }
    }

    // login
    async signin(identifier: string, password: string) {
        const header = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                identifier: identifier, //username or email
                password: password, //password
            }),
        };
        const response = await fetch(API_URL + 'auth/local', header).then(
            (res) => res.json()
        );
        if (response.jwt) {
            this.setCookie('jwt', response.jwt as string, 1);
            const doneQuestionnaire = await this.isDoneQuestionnaire();
            if (doneQuestionnaire) {
                this.setCookie('questionnaireStatus', 'true', 1);
            } else {
                this.setCookie('questionnaireStatus', 'null', 1);
            }
            await this.setCurrentLang();
            return { message: '' };
        } else {
            return { message: response.error.message };
        }
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('user-data');
        localStorage.removeItem('user-role');
        localStorage.removeItem('searchOption');
        localStorage.removeItem('current-questionnaire');
        Cookies.remove('jwt');
        Cookies.remove('questionnaire-page');
        Cookies.remove('questionnaireStatus');
        location.reload();
    }

    async getProvice() {
        const header = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(API_URL + 'provinces', header).then(
            (res) => res.json()
        );
        const modifyData = response.data.map((item: any) => ({
            id: item.id,
            value: item.attributes.value,
        }));
        return modifyData as Array<unknown>;
    }

    async getState() {
        const header = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(API_URL + 'countries', header).then(
            (res) => res.json()
        );
        const modifyData = response.data.map((item: any) => ({
            id: item.id,
            value: item.attributes.value,
        }));
        return modifyData as Array<unknown>;
    }

    async getLanguage() {
        const header = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(API_URL + 'languages', header).then(
            (res) => res.json()
        );
        const modifyData = response.data.map((item: any) => ({
            id: item.id,
            value: item.attributes.label,
        }));
        return modifyData as Array<unknown>;
    }

    async getLanguageAll() {
        const header = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(API_URL + 'languages', header).then(
            (res) => res.json()
        );
        const modifyData = response.data.map((item: any) => ({
            id: item.id,
            value: item.attributes.value,
        }));
        return modifyData as Array<unknown>;
    }

    async getMedicalFalicities() {
        const header = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(
            API_URL + 'medical-facilities',
            header
        ).then((res) => res.json());
        const modifyData = response.data.map((item: any) => ({
            id: item.id,
            value: item.name,
        }));
        return modifyData as Array<unknown>;
    }

    async getCompany() {
        const header = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(
            API_URL + 'insurance-companies',
            header
        ).then((res) => res.json());
        const modifyData = response.data.map((item: any) => ({
            id: item.id,
            value: item.attributes.label,
        }));
        return modifyData as Array<unknown>;
    }

    async getLandingPagePromos(): Promise<Array<unknown>> {
        const header = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        const response = await fetch(
            API_URL + 'landing-page-promos',
            header
        ).then((res) => res.json());

        const dataModify = response.data.map((item: any) => ({
            pName: item.promo.name,
            pImage: IMAGE_URL + ({ ...item.promo }.url as string),
        }));

        return dataModify as Array<unknown>;
    }

    async getProductLinePromos(): Promise<Array<unknown>> {
        const header = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        const response = await fetch(
            API_URL + 'product-line-promos',
            header
        ).then((res) => res.json());

        const dataModify = response.data.map((item: any) => ({
            pName: item.promo.name,
            pImage: IMAGE_URL + ({ ...item.promo }.url as string),
        }));

        return dataModify as Array<unknown>;
    }

    async getAfterSalePromos(): Promise<Array<unknown>> {
        const header = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        const response = await fetch(
            API_URL + 'after-sale-promos',
            header
        ).then((res) => res.json());

        const dataModify = response.data.map((item: any) => ({
            pName: item.promo?.name,
            pImage: IMAGE_URL + ({ ...item.promo }?.url as string),
        }));

        return dataModify as Array<unknown>;
    }

    async getBanner() {
        //jwt get from cookie or /api/auth/local
        const jwt = this.getJWT();
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(API_URL + `banner-info`, options).then(
            (res) => res.json()
        );

        return response as object;
    }

    async getDoctors() {
        const header = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(API_URL + 'doctors', header).then((res) =>
            res.json()
        );
        const modifyData = response.data.map((item: any) => ({
            id: item.id,
            value: item.attributes.name,
        }));
        return modifyData as Array<unknown>;
    }

    async signup(
        email: string,
        firstName: string,
        lastName: string,
        password: string,
        phoneNumber: string,
        address: string,
        state: string,
        provice: string
    ) {
        const header = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: email, //text
                email: email, //email with correct format
                firstName: firstName, //text
                lastName: lastName, //text
                password: password, //password
                phoneNumber: phoneNumber, //text with 10 characters
                address: address, //long text
                language: 1, //Language.id
                state: Number(state), //State.id
                provice: Number(provice), //Provice.id
            }),
        };
        const response = await fetch(
            API_URL + 'auth/local/register',
            header
        ).then((res) => res.json());
        if (response.jwt) {
            await this.signin(email, password);

            const optionsQuestionnaire = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${response.jwt as string}`,
                },
                body: JSON.stringify({
                    data: {},
                }),
            };
            void (await fetch(
                API_URL + 'questionnaires',
                optionsQuestionnaire
            ));
            return { message: '' };
        }
        return { message: response.error.message };
    }

    acceptPDPA = async (isAcceptPDPA: boolean) => {
        const jwt = this.getJWT();
        //jwt get from cookie or /api/auth/local
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
                data: {
                    isAcceptPDPA: isAcceptPDPA,
                },
            }),
        };
        const response = await fetch(API_URL + 'pdpa', options).then((res) =>
            res.json()
        );
        return { message: '' };
    };

    async forgotPassword(email: string) {
        const header = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email, //correct email
            }),
        };
        const response = await fetch(
            API_URL + 'auth/forgot-password',
            header
        ).then((res) => res.json());

        if (response.ok == true) {
            return { message: '' };
        }
        return { message: response.error.message };
    }

    async verifyCodeAndCreateNewPassword(
        code: string,
        newPassword: string,
        confirmNewPassword: string
    ) {
        const header = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                code: code, //code from email
                password: newPassword, //new password with correct format
                passwordConfirmation: confirmNewPassword, //new password with correct format
            }),
        };
        const response = await fetch(
            API_URL + 'auth/reset-password',
            header
        ).then((res) => res.json());

        if (response.data != null) {
            return { message: '' };
        }
        return { message: response.error.message };
    }

    async getCurrentUser() {
        //jwt get from cookie or /api/auth/local
        const jwt = this.getJWT();
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json',
            },
        };
        const response = await fetch(API_URL + `profile`, options).then((res) =>
            res.json()
        );
        if (response.dateOfBirth != null) {
            const birthDate = response.dateOfBirth.split('-');
            const dob = new Date(
                new Date(
                    Number(birthDate[0]),
                    Number(birthDate[1]),
                    Number(birthDate[2])
                )
            );
            const diff = new Date(Date.now() - dob.getTime());
            const age = Math.abs(diff.getUTCFullYear() - 1970).toString();
            return JSON.stringify({ ...response, age: age });
        }
        return JSON.stringify({ ...response, age: 0 });
    }

    createUserData(
        first_name: string,
        last_name: string,
        blood_type: string,
        address: string,
        phone_number: string,
        height: string,
        weight: string,
        age: string,
        sex: string
    ) {
        localStorage.setItem(
            'user-data',
            JSON.stringify({
                first_name: first_name,
                last_name: last_name,
                blood_type: blood_type,
                address: address,
                phone_number: phone_number,
                height: height,
                weight: weight,
                age: age,
                sex: sex,
                data: true,
            })
        );
    }

    async getUserData(profileId?: any) {
        let id = await this.getID();
        if (profileId) {
            id = profileId;
        }
        //jwt get from cookie or /api/auth/local
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const response = await fetch(API_URL + `profile/${id}`, options).then(
            (res) => res.json()
        );

        const birthDate = response.dateOfBirth.split('-');
        const dob = new Date(
            new Date(
                Number(birthDate[0]),
                Number(birthDate[1]),
                Number(birthDate[2])
            )
        );
        const diff = new Date(Date.now() - dob.getTime());
        const age = Math.abs(diff.getUTCFullYear() - 1970).toString();
        return JSON.stringify({ ...response, age: age });
    }

    getCurrentQuestionnaire() {
        if (!localStorage.getItem('current-questionnaire')) {
            localStorage.setItem('current-questionnaire', (0).toString());
        }
        const currentQuestionnaire = localStorage.getItem(
            'current-questionnaire'
        );
        return parseInt(<string>currentQuestionnaire, 10);
    }

    updateCurrentQuestionnaire(count: number) {
        const currentQuestionnaire = this.getCurrentQuestionnaire();
        if (currentQuestionnaire + 1 > 0 && currentQuestionnaire - 1 < 4) {
            localStorage.setItem(
                'current-questionnaire',
                (currentQuestionnaire + count).toString()
            );
        }
    }

    async getNearestMedicalFacilitiesList() {
        const pos = { lat: 33.5, lng: 46.7 };
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                pos.lat = position.coords.latitude;
                pos.lng = position.coords.longitude;
            });
        }
        const searchCriteria = JSON.stringify({
            searchCriteria: {
                lat: pos.lat, //double
                lng: pos.lng, //double
            },
        });
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const response = await fetch(
            API_URL + `medical-facility/${searchCriteria}`,
            options
        ).then((res) => res.json());

        // const image = IMAGE_URL.concat(
        //     response.medicalFacilityImage.url as string
        // );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataModify = {
            pImage:
                IMAGE_URL +
                ({ ...response.medicalFacilityImage }.url as string),
            pName: response.name,
            pDetail: response.detail,
            pPhone: response.phoneNumber,
            pType: response.type,
        };

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return dataModify;
    }

    async getAllMedicalFacilitiesList() {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const response = await fetch(
            API_URL + 'medical-facilities',
            options
        ).then((res) => res.json());
        // IMAGE_URL + ({ ...item.medicalFacilityImage }.url as string)
        const dataModify = response.data.map((item: any) => ({
            pImage:
                IMAGE_URL + ({ ...item.medicalFacilityImage }.url as string),
            pName: item.name,
            pDetail: item.detail,
            pPhone: item.phoneNumber,
            pType: item.type,
        }));

        const dataList = [];
        for (const key in dataModify) {
            dataList.push(dataModify[key]);
        }

        dataList.length = Math.min(dataList.length, 10);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return dataList;
    }

    setSearchOption(searchText: string, placeType: string) {
        localStorage.setItem(
            'searchOption',
            JSON.stringify({
                searchText: searchText,
                placeType: placeType,
            })
        );
    }

    async getMedicalFacilitiesListSearchSortByOption() {
        const searchOption = JSON.parse(this.getItem('searchOption'));
        console.log(searchOption);
        // if (searchOption.placeType == '3' || searchOption.placeType == '0') {
        //     return this.getAllMedicalFacilitiesList();
        // }
        if (
            (searchOption.placeType == '3' || searchOption.placeType == '0') &&
            searchOption.searchText === ''
        ) {
            return this.getAllMedicalFacilitiesList();
        }
        if (searchOption.placeType == '1') {
            searchOption.placeType = 'Hospital';
        } else if (searchOption.placeType == '2') {
            searchOption.placeType = 'Clinic';
        }
        const searchCriteria = JSON.stringify({
            searchCriteria: {
                type: searchOption.placeType, //ENUM("Clinic","Hospital")
                textSearch: searchOption.searchText, //text
            },
        });
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const response = await fetch(
            API_URL + `medical-facilities/${searchCriteria}`,
            options
        ).then((res) => res.json());

        const dataModify = response.data.map((item: any) => ({
            pImage:
                IMAGE_URL + ({ ...item.medicalFacilityImage }.url as string),
            pName: item.name,
            pDetail: item.detail,
            pPhone: item.phoneNumber,
            pType: item.type,
        }));

        const dataList = [];
        for (const key in dataModify) {
            dataList.push(dataModify[key]);
        }

        dataList.length = Math.min(dataList.length, 10);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return dataList;
    }

    async getProfileData() {
        const jwt = this.getJWT();
        const id = await this.getID();
        //console.log(id);
        //jwt get from cookie or /api/auth/local
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + jwt,
            },
        };
        const response = await fetch(API_URL + `profile/${id}`, options).then(
            (res) => res.json()
        );

        return JSON.stringify(response);
    }

    async getProfileImage(profileId?: any) {
        const jwt = this.getJWT();
        let id = await this.getID();
        if (profileId) {
            id = profileId;
        }
        //jwt get from cookie or /api/auth/local
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(API_URL + `profile/${id}`, options).then(
            (res) => res.json()
        );

        if (response.profileImage.length === 0) {
            return null;
        }

        const profileImages = response.profileImage;
        profileImages.sort((a: any, b: any) => b?.id - a?.id);

        return IMAGE_URL + (profileImages[0].url as string);
    }

    async uploadProfileImage(profileImage: File, imageUrl: string) {
        const jwt = this.getJWT();
        const id = await this.getID(); //User.id

        const imageFile = new File([profileImage as Blob], profileImage.name, {
            type: profileImage.type,
        });
        //jwt get from cookie or /api/auth/local

        const form = new FormData();
        form.append('files', profileImage); //files from Form input type="file"
        form.append('ref', 'plugin::users-permissions.user'); //please press follow this ref
        form.append('refId', id); //user.id
        form.append('field', 'profileImage'); //please press follow this ref

        // const form = {
        //     files: imageUrl,
        //     ref: 'plugin::users-permissions.user',
        //     refId: id,
        //     field: 'profileImage',
        // };

        // console.log('files:', form.get('files'));
        const options = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
            body: form,
        };

        try {
            const response = await fetch(API_URL + 'upload', options);
            await response.json();
        } catch (error) {
            console.error('Error:', error);
        }
    }

    updateProfileData = async (
        firstName: string,
        lastName: string,
        userEmail: string,
        contactNumber: string,
        stateInCountry: string,
        streetAddress: string,
        city: string,
        dateOfBirth: string,
        sex: string
    ) => {
        const jwt = this.getJWT();
        //jwt get from cookie or /api/auth/local
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
                data: {
                    dateOfBirth: dateOfBirth,
                    sex: sex,
                    email: userEmail,
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber: contactNumber,
                    address: streetAddress,
                    language: 1,
                    state: stateInCountry,
                    provice: city,
                },
            }),
        };
        const response = await fetch(API_URL + 'profile', options).then((res) =>
            res.json()
        );
        return { message: '' };
    };

    changePassword = async (
        currentPassword: string,
        newPassword: string,
        confirmNewPassword: string
    ) => {
        const jwt = this.getJWT();
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
                currentPassword: currentPassword, //current password
                password: newPassword, //new password
                passwordConfirmation: confirmNewPassword, //new password
            }),
        };
        const response = await fetch(
            API_URL + 'auth/change-password',
            options
        ).then((res) => res.json());

        if (response.error) {
            return { message: response.error.message };
        }
        return { message: '' };
    };

    async getSetting() {
        const jwt = this.getJWT();
        //jwt get from cookie or /api/auth/local
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(API_URL + 'setting', options).then((res) =>
            res.json()
        );
        const modifyData = {
            id: response.id,
            isEmailNotification: response.isEmailNotification,
            isPushNotification: response.isPushNotification,
            language: response.language.id,
            lang: response.language.value,
        };
        return JSON.stringify(modifyData);
    }

    async updateSetting(
        isEmailNotification: boolean,
        isPushNotification: boolean,
        language: string
    ) {
        const jwt = this.getJWT();
        //jwt get from cookie or /api/auth/local
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
                data: {
                    isEmailNotification: isEmailNotification, //boolean
                    isPushNotification: isPushNotification, //boolean
                    language: language, //Language.id
                },
            }),
        };
        const response = await fetch(API_URL + 'setting', options).then((res) =>
            res.json()
        );
        await this.setCurrentLang();
        return response.language as string;
    }

    convertDateTime(dateTimeString: string) {
        const date = new Date(dateTimeString);

        const options: Intl.DateTimeFormatOptions = {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: false,
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            ...options,
            timeZone: 'Europe/London',
        }).format(date);

        return formattedDate;
    }

    async getNotifications() {
        const jwt = this.getJWT();
        // const jwt = this.getJWT();
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(API_URL + 'notifications', options).then(
            (res) => res.json()
        );
        const newData = [] as object[];
        response.data.forEach((noti: any) => {
            newData.push({
                title: noti.title,
                detail: noti.detail,
                ref: noti.ref,
                time: this.convertDateTime(noti.publishedAt as string),
            });
        });
        return newData;
    }

    async getEmergencies() {
        const jwt = this.getJWT();
        // const jwt = this.getJWT();
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(API_URL + 'emergencies', options).then(
            (res) => res.json()
        );
        const newData = [] as object[];
        response.data.forEach((emer: any) => {
            const { patient, medicalFacility } = emer;
            newData.push({
                patientName:
                    (patient.firstName as string) +
                    ' ' +
                    (patient.lastName as string),
                medicalFacility: medicalFacility.name,
                updatedOn: this.convertDateTime(emer.updatedAt as string),
                detail: emer.detail,
                status: emer.status,
                approvedOn:
                    emer.approvedOn == null
                        ? 'N/A'
                        : this.convertDateTime(emer.approvedOn as string),
            });
        });
        return newData;
    }

    // async getEmergenciesLongPolling() {
    //     const jwt = this.getJWT();
    //     // const jwt = this.getJWT();
    //     const options = {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${jwt}`,
    //         },
    //     };
    //     const response = await fetch(API_URL + 'emergencies', options).then(
    //         (res) => res.json()
    //     );
    //     return response;
    //     // const newData = [] as object[];
    //     // response.data.forEach((emer: any) => {
    //     //     const { patient, medicalFacility } = emer;
    //     //     newData.push({
    //     //         patientName:
    //     //             (patient.firstName as string) +
    //     //             ' ' +
    //     //             (patient.lastName as string),
    //     //         medicalFacility: medicalFacility.name,
    //     //         updatedOn: this.convertDateTime(emer.updatedAt as string),
    //     //         detail: emer.detail,
    //     //         status: emer.status,
    //     //         approvedOn:
    //     //             emer.approvedOn == null
    //     //                 ? 'N/A'
    //     //                 : this.convertDateTime(emer.approvedOn as string),
    //     //     });
    //     // });
    //     // return newData
    // }

    async getMedicalFacilityRecordHome() {
        const jwt = this.getJWT();
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(
            API_URL + `medical-facility-record`,
            options
        ).then((res) => res.json());

        const resHosp = [] as any[];
        const resClin = [] as any[];
        response.data.forEach((record: any) => {
            const {
                address,
                detail,
                name,
                phoneNumber,
                medicalFacilityImage,
                id,
            } = record;
            const { url }: { url: string } = medicalFacilityImage;
            const newRecord = {
                pImage: IMAGE_URL + url,
                pName: name,
                pDetail: detail,
                pPhone:
                    (address as string) + '<br />' + (phoneNumber as string),
                pType: id,
            };

            if (record.type == 'Clinic') {
                resClin.push(newRecord);
            } else {
                resHosp.push(newRecord);
            }
        });
        return [resHosp, resClin] as [any[], any[]];
    }

    convertTime(timeStr: string) {
        const timeObj = new Date(`2000-01-01T${timeStr}Z`);
        const optionsTime: Intl.DateTimeFormatOptions = {
            hour: 'numeric',
            minute: '2-digit',
            hour12: false,
        };
        const formattedTime = timeObj.toLocaleTimeString('en-US', {
            ...optionsTime,
            timeZone: 'Europe/London',
        });
        return formattedTime;
    }

    convertDate(dateStr: string) {
        const dateObj = new Date(dateStr);
        const optionsDate: Intl.DateTimeFormatOptions = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
        };
        const formatter = new Intl.DateTimeFormat('en-US', {
            ...optionsDate,
            timeZone: 'Europe/London',
        });
        const formattedDate = formatter.format(dateObj);
        return formattedDate;
    }

    async getMedicalFacilityRecordFlow1(pID: string) {
        const jwt = this.getJWT();
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(
            API_URL + `medical-facility-record/treatment-records/${pID}`,
            options
        ).then((res) => res.json());
        const newData = [] as any[];
        response.data.forEach((appontment: any) => {
            const { appointment, id } = appontment;
            const { date, fromTime, diseases } = appointment;
            const symptomList = [] as string[];
            diseases.forEach((symptom: any) => {
                symptomList.push(symptom.label as string);
            });

            const formattedDate = this.convertDate(date as string);
            const formattedTime = this.convertTime(fromTime as string);
            newData.push({
                appointmentId: id,
                symptom: symptomList.join(', '),
                date: formattedDate + ' ' + formattedTime,
            });
        });
        return [newData] as [any[]];
    }

    async getMedicalFacilityRecordFlow2(id: string) {
        const jwt = this.getJWT();
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(
            API_URL + `medical-facility-record/treatment-record/${id}`,
            options
        ).then((res) => res.json());
        const {
            doctor,
            diagnosis,
            medicalFacility,
            fromDate,
            fromTime,
            toDate,
            toTime,
            appointment,
        } = response;
        const newData = {
            docName: doctor.name,
            docDetal: doctor.doctorBio,
            docImage: IMAGE_URL + ({ ...doctor.profileImage }.url as string),
            diagnosis: diagnosis,
            phoneNumber: medicalFacility.phoneNumber,
            fromDate: this.convertDate(fromDate as string),
            toDate: this.convertDate(toDate as string),
            fromTime: this.convertTime(fromTime as string),
            toTime: this.convertTime(toTime as string),
            appId: appointment.id,
            status: true,
        };
        return newData as object;
    }

    async getMedicalFacilityRecordFlow3(id: string) {
        const jwt = this.getJWT();
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(
            API_URL + `medical-facility-record/request-for-record/${id}`,
            options
        ).then((res) => res.json());
        return '';
    }

    async getDiseases() {
        const header = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(API_URL + 'diseases', header).then((res) =>
            res.json()
        );
        const modifyData = response.data.map((item: any) => ({
            id: item.id,
            value: item.attributes.label,
        }));
        return modifyData as Array<unknown>;
    }

    getDateAppointmentHome(inputDate: string) {
        // Create a new Date object from the input date string
        const date = new Date(inputDate);

        // Define an array of month names
        const monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];

        // Get the month, day, and year values from the date object
        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        // Create a new formatted date string using the month, day, and year values
        const formattedDate = `${month} ${day} ${year}`;

        return formattedDate; // Output: "Feb 20 2023"
    }

    getNMonthBefore(n: number) {
        const today = new Date();

        // Get the year and month from the date object
        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDate();

        // Create a new date one month before today's date
        const oneMonthBefore = new Date(year, month - n, day);

        // Format the date as a string in 'yyyy-mm-dd' format
        const formattedDate = `${oneMonthBefore.getFullYear()}-${(
            oneMonthBefore.getMonth() + 1
        )
            .toString()
            .padStart(2, '0')}-${oneMonthBefore
            .getDate()
            .toString()
            .padStart(2, '0')}`;

        // Output the result
        return formattedDate;
    }

    async getAppoitmentHome(date: string = this.getNMonthBefore(0)) {
        const jwt = this.getJWT();
        const searchCriteria = JSON.stringify({
            searchCriteria: {
                date: date,
            },
        });
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(
            API_URL + `appointments/home/${searchCriteria}`,
            options
        ).then((res) => res.json());

        const newData = [] as Array<any>;
        response.data.forEach((appointment: any) => {
            newData.push({
                doctorName: appointment.doctor.name,
                detail: appointment.appointmentDetail,
                place: appointment.medicalFacility.name,
                placeLocation: appointment.medicalFacility.address,
                imageSrc:
                    IMAGE_URL + (appointment.doctor.profileImage.url as string),
                timeRange: `${this.convertTime(
                    appointment.fromTime as string
                )}${
                    appointment.toTime
                        ? ' to ' +
                          this.convertTime(appointment.toTime as string)
                        : ''
                }`,

                header: 'up comming',
                date: this.getDateAppointmentHome(appointment.date as string),
                isAccept: appointment.isAccept,
            });
        });
        return [newData];
    }

    async getAppoitmentFlow2(timeCriteria: string | null) {
        const jwt = this.getJWT();

        const searchCriteria = JSON.stringify({
            searchCriteria: {
                date: timeCriteria,
            },
        });
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(
            API_URL + `appointments/doctors/${searchCriteria}`,
            options
        ).then((res) => res.json());
        //console.log(response);
        const newData = [] as Array<any>;
        response.data.forEach((appointment: any) => {
            const dateList = [] as string[];
            const timeRangeList = [] as string[];
            appointment.availableTime.forEach((time: any) => {
                dateList.push(time.weekDay as string);
                timeRangeList.push(this.convertTime(time.fromTime as string));
            });

            newData.push({
                header:
                    (appointment.id as string) +
                    '+' +
                    (appointment.medicalFacility.id as string),
                doctorName: appointment.name,
                detail: appointment.doctorBio,
                place: appointment.medicalFacility.name,
                placeLocation: appointment.medicalFacility.address,
                date: dateList,
                timeRange: timeRangeList,
                imageSrc: IMAGE_URL + (appointment.profileImage.url as string),
            });
        });
        return [newData];
    }

    async postAppoitmentFlow3(
        symptoms: Array<number>,
        medicalFacility: number,
        doctor: number,
        date: string,
        fromTime: string,
        detail: string
    ) {
        const jwt = this.getJWT();
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
                data: {
                    diseases: symptoms, //list of Disease.id
                    medicalFacility: medicalFacility, //MedicalFacility.id
                    doctor: doctor, //Doctor.id
                    date: date,
                    fromTime: fromTime + ':00.000',
                    appointmentDetail: detail,
                },
            }),
        };
        const response = await fetch(API_URL + `appointments`, options).then(
            (res) => res.json()
        );
    }

    convertDateTimeDevice(dateTime: string) {
        const date = new Date(dateTime);

        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        };

        const formattedDate = new Intl.DateTimeFormat('en-US', {
            ...options,
            timeZone: 'Europe/London',
        }).format(date);
        return formattedDate;
    }

    addSevenHoursTimestamp(dataArr: [number, number][]): [number, number][] {
        return dataArr.map(([timestamp, value]: [number, number]) => {
            const date = new Date(timestamp);
            date.setHours(date.getHours() + 7);
            return [date.getTime(), value];
        });
    }

    async getDeviceData(dateFrom: string, dateTo: string) {
        const jwt = this.getJWT();
        const searchCriteria = JSON.stringify({
            searchCriteria: {
                date: dateFrom,
                dateTo: dateTo,
            },
        });
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(
            API_URL + `device-datas/${searchCriteria}`,
            options
        ).then((res) => res.json());
        const myData = {
            heart: [] as Array<Array<any>>,
            step: [] as Array<Array<any>>,
            temp: [] as Array<Array<any>>,
            spo2: [] as Array<Array<any>>,
            blood: {
                bloodDia: [] as Array<Array<any>>,
                bloodSys: [] as Array<Array<any>>,
            },
        };

        await response.data.forEach((eachData: any) => {
            const {
                stepCount,
                temperature,
                heartRate,
                spo2,
                bloodPressureDia,
                bloodPressureSys,
                dateTime,
            } = eachData;
            // const label = this.convertDateTimeDevice(dateTime as string);
            // const label = this.convertDateTimeDevice(dateTime as string);

            let label = new Date(dateTime as string).getTime();
            label = label + 7 * 60 * 60 * 1000;
            myData.heart.push([label, heartRate as number]);
            myData.step.push([label, stepCount as number]);
            myData.temp.push([label, temperature as number]);
            myData.spo2.push([label, spo2 as number]);
            myData.blood.bloodDia.push([label, bloodPressureDia as number]);
            myData.blood.bloodSys.push([label, bloodPressureSys as number]);
        });
        return myData;
    }

    async getDeviceDataTracking(dateFrom: string, dateTo: string) {
        const jwt = this.getJWT();
        const searchCriteria = JSON.stringify({
            searchCriteria: {
                date: dateFrom,
                dateTo: dateTo,
            },
        });
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(
            API_URL + `device-datas/${searchCriteria}`,
            options
        ).then((res) => res.json());

        type myDataProps = {
            id: number;
            device: string;
            dateTime: number;
            heart: number;
            step: number;
            temp: number;
            spo2: number;
            bloodDia: number;
            bloodSys: number;
            lng: number;
            lat: number;
            battery: number;
        }[];

        const myData: myDataProps = [];

        await response.data.forEach((eachData: any, index: number) => {
            const {
                id,
                device,
                dateTime,
                heartRate,
                bloodPressureSys,
                bloodPressureDia,
                spo2,
                temperature,
                stepCount,
                lng,
                lat,
                battery,
            } = eachData;

            const label = new Date(dateTime as string).getTime();
            myData.push({
                id: id,
                device: device.deviceName,
                dateTime: label,
                heart: heartRate,
                step: stepCount,
                temp: temperature,
                spo2: spo2,
                bloodDia: bloodPressureDia,
                bloodSys: bloodPressureSys,
                lng: lng,
                lat: lat,
                battery: battery,
            });
        });

        return myData;
    }

    async getDeviceDatalongPolling() {
        const jwt = this.getJWT();
        const searchCriteria = JSON.stringify({
            searchCriteria: {
                date: '2020-05-03',
                dateTo: '2024-05-11',
            },
        });
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(
            API_REALTIME_URL + `device-datas/${searchCriteria}`,
            options
        );
        if (response.ok) {
            const message = await response.json();

            const myData = {
                heart: [] as Array<Array<any>>,
                step: [] as Array<Array<any>>,
                temp: [] as Array<Array<any>>,
                spo2: [] as Array<Array<any>>,
                blood: {
                    bloodDia: [] as Array<Array<any>>,
                    bloodSys: [] as Array<Array<any>>,
                },
            };
            await message.data.forEach((eachData: any) => {
                const {
                    stepCount,
                    temperature,
                    heartRate,
                    spo2,
                    bloodPressureDia,
                    bloodPressureSys,
                    dateTime,
                } = eachData;
                // const label = this.convertDateTimeDevice(dateTime as string);
                let label = new Date(dateTime as string).getTime();
                label = label + 7 * 60 * 60 * 1000;
                myData.heart.push([label, heartRate as number]);
                myData.step.push([label, stepCount as number]);
                myData.temp.push([label, temperature as number]);
                myData.spo2.push([label, spo2 as number]);
                myData.blood.bloodDia.push([label, bloodPressureDia as number]);
                myData.blood.bloodSys.push([label, bloodPressureSys as number]);
            });
            return { data: myData, status: 'ok' };
        }
        return { status: 'fail' };
    }

    async getDeviceDatalongPollingTracking() {
        const jwt = this.getJWT();

        const today = new Date();
        const sixMonthsAgo = new Date(today);
        sixMonthsAgo.setMonth(today.getMonth() - 6);

        const formatDate = (date: Date) => date.toISOString().split('T')[0];
        const formattedToday = formatDate(today);
        const formattedSixMonthsAgo = formatDate(sixMonthsAgo);

        const searchCriteria = JSON.stringify({
            searchCriteria: {
                date: formattedSixMonthsAgo,
                dateTo: formattedToday,
            },
        });
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(
            API_REALTIME_URL + `device-datas/${searchCriteria}`,
            options
        );
        if (response.ok) {
            const message = await response.json();

            type myDataProps = {
                id: number;
                device: string;
                dateTime: number;
                heart: number;
                step: number;
                temp: number;
                spo2: number;
                bloodDia: number;
                bloodSys: number;
                lng: number;
                lat: number;
                battery: number;
            }[];

            const myData: myDataProps = [];

            await message.data.forEach((eachData: any, index: number) => {
                const {
                    id,
                    device,
                    dateTime,
                    heartRate,
                    bloodPressureSys,
                    bloodPressureDia,
                    spo2,
                    temperature,
                    stepCount,
                    lng,
                    lat,
                    battery,
                } = eachData;

                const label = new Date(dateTime as string).getTime();
                myData.push({
                    id: id,
                    device: device.deviceName,
                    dateTime: label,
                    heart: heartRate,
                    step: stepCount,
                    temp: temperature,
                    spo2: spo2,
                    bloodDia: bloodPressureDia,
                    bloodSys: bloodPressureSys,
                    lng: lng,
                    lat: lat,
                    battery: battery,
                });
            });
            return { data: myData, status: 'ok' };
        }
        return { status: 'fail' };
    }

    async getLocation() {
        // Format the date as a string in 'yyyy-mm-dd' format

        const jwt = this.getJWT();
        const searchCriteria = JSON.stringify({
            searchCriteria: {
                date: this.getNMonthBefore(2),
                dateTo: this.getNMonthBefore(0),
            },
        });
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(
            API_URL + `device-datas/${searchCriteria}`,
            options
        ).then((res) => res.json());

        if (response.data.length == 0) {
            return { status: 'Data not found' };
        }
        const myData = {
            lat: response.data[0].lat as number,
            lng: response.data[0].lng as number,
            dateTime: this.convertDateTimeDevice(
                response.data[0].dateTime as string
            ),
            status: 'ok',
        };
        return myData;
    }

    async getLocationLongPolling() {
        const jwt = this.getJWT();
        const searchCriteria = JSON.stringify({
            searchCriteria: {
                date: this.getNMonthBefore(2),
                dateTo: this.getNMonthBefore(0),
            },
        });
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(
            API_REALTIME_URL + `device-datas/${searchCriteria}`,
            options
        );

        if (response.ok) {
            const message = await response.json();
            if (message.data.length == 0) {
                return { status: 'Data not found' };
            }
            const myData = {
                lat: message.data[0].lat as number,
                lng: message.data[0].lng as number,
                dateTime: this.convertDateTimeDevice(
                    message.data[0].dateTime as string
                ),
                status: 'ok',
            };
            return myData;
        }

        return { status: 'Data not found' };
    }

    getCurrentDateTrack() {
        const date = new Date(); // Replace with your desired date and time

        const options: Intl.DateTimeFormatOptions = {
            month: 'short', // Use abbreviated month name
            day: 'numeric', // Use numeric day (1-31)
            year: 'numeric', // Use numeric year (e.g., 2023)
            hour: 'numeric', // Use numeric hour (0-23)
            minute: 'numeric', // Use numeric minute (0-59)
        };

        const formattedDate = date.toLocaleDateString('en-US', options);

        return formattedDate;
    }

    async getEmergencyAlert() {
        const jwt = this.getJWT();
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        };
        const response = await fetch(
            API_REALTIME_URL + `emergency/alert`,
            options
        );
        if (response.ok) {
            const message = await response.json();
            const myData = {
                lat: message.data[0].lat as number,
                lng: message.data[0].lng as number,
                dateTime: this.convertDateTimeDevice(
                    message.data[0].dateTime as string
                ),
                status: 'ok',
            };
            return myData;
        }
        return { status: 'Data not found' };
    }

    async checkConnectFirebaseApi(token: string) {
        const jwt = this.getJWT();
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
                data: { firebaseWebNotificationToken: token },
            }),
        };
        try {
            const response = await fetch(
                'https://stickback.techcaresystem.com/api/profile',

                options
            );
            console.log(response);
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    requestForToken = async () => {
        const permisstion = await Notification.requestPermission();
        if (permisstion === 'granted') {
            return getToken(messaging, {
                vapidKey:
                    'BJw6XKwTsLfFhYqV78mtckZXqiqFSgtNCQPJ7-gLlT-pTmlzoKXFLJ2uz0GG5bkfjscLB3wNfdfZEyR286U-Atw',
            })
                .then((currentToken) => {
                    if (currentToken) {
                        // console.log('current token for client: ', currentToken);
                        return currentToken;
                        // void this.checkConnectFirebaseApi(currentToken);
                        // Perform any other neccessary action with the token
                    } else {
                        // Show permission request UI
                        console.log(
                            'No registration token available. Request permission to generate one.'
                        );
                    }
                })
                .catch((err) => {
                    console.log(
                        'An error occurred while retrieving token. ',
                        err
                    );
                });
        } else if (permisstion === 'denied') {
            console.log('You denied for the notification');
        }
    };

    onMessageListener = () =>
        new Promise((resolve) => {
            onMessage(messaging, (payload) => {
                console.log('payload', payload);
                resolve(payload);
            });
        });
}

export default new AuthService();
