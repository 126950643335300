import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { LayoutProps } from '@doar/shared/styled';
import { MapKey } from '@doar/shared/data';
import Map from './map';
import Marker from './marker';
import { StyledMap } from './style';
import { useState, useEffect } from 'react';

const render = (status: Status) => {
    return <h1>{status}</h1>;
};

type MapProps = google.maps.MapOptions &
    LayoutProps & {
        marker?: boolean;
        locationData?: {
            lat?: number | undefined;
            lng?: number | undefined;
            status?: string;
        };
    };

const MyMap: React.FC<MapProps> = ({
    width,
    height,
    marker,
    locationData,
    ...options
}) => {
    const [latitude, setLatitude] = useState<number>(0);
    const [longitude, setLongitude] = useState<number>(0);
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [mapZoom, setMapZoom] = useState(12);

    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const myLatitude = position.coords.latitude;
                const myLongitude = position.coords.longitude;

                setLatitude(myLatitude);
                setLongitude(myLongitude);

                // Check if locationData is defined, and use it if available
                if (
                    locationData &&
                    locationData.lat !== undefined &&
                    locationData.lng !== undefined
                ) {
                    const newMapCenter = {
                        lat: (myLatitude + locationData.lat) / 2,
                        lng: (myLongitude + locationData.lng) / 2,
                    };
                    setMapCenter(newMapCenter);

                    // Calculate an appropriate zoom level to fit both markers within the visible area
                    const zoom = calculateZoomLevel(
                        { lat: myLatitude, lng: myLongitude },
                        { lat: locationData.lat, lng: locationData.lng }
                    );
                    setMapZoom(zoom);
                }
            });
        } else {
            console.info('Geolocation is not available in this browser.');
        }
    }, [locationData]);

    // Function to calculate an appropriate zoom level to fit both markers on the map
    const calculateZoomLevel = (marker1: any, marker2: any) => {
        const GLOBE_WIDTH = 256; // The width of the Google Map tiles
        const west = marker1.lng;
        const east = marker2.lng;
        let angle = east - west;
        if (angle < 0) {
            angle += 360;
        }
        const zoom = Math.round(
            Math.log((window.innerWidth * 360) / angle / GLOBE_WIDTH) / Math.LN2
        );
        return zoom;
    };

    return (
        <StyledMap width={width} height={height}>
            <Wrapper apiKey={MapKey} render={render}>
                <Map {...options} center={mapCenter} zoom={mapZoom}>
                    {marker && (
                        <Marker position={{ lat: latitude, lng: longitude }} />
                    )}
                    {locationData?.status === 'ok' && (
                        <Marker
                            position={{
                                lat: locationData?.lat ?? 0,
                                lng: locationData?.lng ?? 0,
                            }}
                        />
                    )}
                </Map>
            </Wrapper>
        </StyledMap>
    );
};

MyMap.defaultProps = {
    center: { lat: 0, lng: 0 },
    width: '100%',
    height: '600px',
    zoom: 12,
};

export default MyMap;
