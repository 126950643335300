const resources = {
    en: {
        translation: {
            header: {
                home: 'Home',
                track: 'Track',
                makeApp: 'Appointments',
                treatment: 'Treatment Records',
                hospAndClin: 'Hospitals and Clinics',
                graph: 'Graph',
                about: 'About Us',
                newsEvent: 'News and Event',
                noti: 'NOTIFICATIONS',
                view: 'VIEW ALL NOTIFICATIONS',
                viewP: 'View Profile',
                sign: 'Sign Out',
            },
            footer: {
                cate: 'CATEGORY',
                cont: 'CONTACT',
                get: 'GET THE APP',
                track: 'TRACK',
                makeApp: 'MAKE AN APPOINTMENT',
                hospAndClin: 'HOSPITAL AND CLINIC',
                graph: 'GRAPH',
                about: 'ABOUT US',
                home: 'HOME',
            },
            home: {
                techCare1: 'Hello',
                techCare2: 'always by your side',
                techCare3:
                    'Managing your health and access to care have never been this good, this easy and this fast',
                ourSer: 'Our Service',
                partner: 'Partner',
                over: 'Overview',
                blood: 'Blood Pressure',
                bloodDesc:
                    'Measurements analysis, statistics, graph to analyse high or low blood pressure!',
                battery: 'Battery',
                heart: 'Heart Rate',
                heartDesc: 'you can measure and monitor your heart rate!',
                step: 'Step Count',
                stepDesc:
                    'Measurements analysis, statistics, graph to analyse high or low blood pressure!',
                temp: 'Temperature',
                tempDesc:
                    'Measurements analysis, statistics, graph to analyse high or low blood pressure!',
                spo2: 'SPO2',
                spo2Desc: 'you can measure and monitor your heart rate!',
                see: 'See more',
                near: 'Nearest Hospitals and Clinics',
                make: 'Make An Appointment',
                find: 'Find Hospitals and Clinics',
                findNear: 'find nearest hospital and clinic',
                search: 'Search',
            },
            profile: {
                emailNoti: 'Email notification',
                pushNoti: 'Push notification',
                language: 'Language',
                logOut: 'Logout',
                sex: 'Sex',
                age: 'Age',
                blood: 'Blood',
                weight: 'Weight',
                height: 'Height',
                diagnostic: 'Diagnostic',
                editProfileBtn: 'Edit Profile',
                editQuestionnaire: 'Edit Questionnare',
                back: 'Back',
                editProfile: {
                    changeProfile: 'Change Profile',
                    close: 'Close',
                    saveChange: 'Save Changes',
                    firstName: 'Firstname',
                    lastName: 'Lastname',
                    sex: 'Sex',
                    dob: 'Date of birth',
                    email: 'Email',
                    contact: 'Contact Number',
                    city: 'City/province',
                    state: 'State',
                    address: 'Address',
                    submit: 'Submit',
                    changePasswordBtn: 'Change Password',
                    changePassword: {
                        password: 'Password',
                        new: 'New Password',
                        confirm: 'Confirm New Password',
                        confirmBtn: 'Confirm',
                    },
                },
            },
            track: {
                track: 'Track',
                trackMy: 'Track My Stick',
                latest: 'Latest',
                home: 'Home',
                emer: 'Emergency',
                record: 'Hospital/Clinic Record',
                hosp: 'Hospital',
                clin: 'Clinic',
                notFound: 'Record not found',
                today: "Today's schedule",
                schedule: 'Schedule',
            },
            makeApp: {
                please: 'Please Select Symptom',
                appointment: 'Appointment',
                available: 'Available Time',
                make: 'Make An Appointment',
                back: 'Back',
                detail: 'Detail',
                date: 'Date',
                time: 'Time',
                cancel: 'Cancel',
                confirm: 'Confirm Appointment',
            },
            graph: {
                graph: 'Graph',
                blood: 'Blood Pressure',
                heart: 'Heart Rate',
                step: 'Step Count',
                temp: 'Temperature',
                spo2: 'SPO2',
                hour: 'HOUR',
                day: 'Day',
                week: 'Week',
                month: 'Month',
                year: 'Year',
                all: 'All',
                to: 'To',
            },
            hospAndClin: {
                enter: 'Enter Hospital or Clinic name',
                please: 'Please Select',
                hops: 'Hospital',
                clin: 'Clinic',
                make: 'Make An Appointment',
            },
            about: {
                ourService: 'Our Service',
            },
            questionnaire: {
                questionnaire: 'Questionnaire',
                prev: 'Previous',
                fin: 'Finish',
                next: 'Next',
                save: 'Saving...',
                submit: 'Submitting...',
                yes: 'yes',
                no: 'no',
                q1: {
                    weight: 'Weight (kg.)',
                    height: 'Height (cm.)',
                    bloodGroup: 'Blood group',
                    rhtype: 'Rh type',
                    caretaker:
                        'Please provide us with a name of caretaker to contact.',
                    emergencyContact:
                        'Please provide us with a name of person to contact during emergency situations.',
                    firstName: 'Firstname',
                    lastName: 'Lastname',
                    email: 'Email',
                    number: 'Mobile Number',
                },
                q2: {
                    mostVisitedHospital:
                        'Kindly list the hospitals you frequntly visit.',
                    no1: 'No.1',
                    no2: 'No.2',
                    other: 'Others? (Please specify)',
                    memberNum: 'Hospital member card number. (If any)',
                    disease: 'Do you have any health conditions or illnesses?',
                    listDisease: 'Please specify the diseases and/or symptoms.',
                    bloodPressure:
                        'If you have heart disease or blood pressure issue kindly fill in your normal range (mmHg).',
                    from: 'From',
                    to: 'To',
                    bpm: 'If you have heart disease or heart rate issue kindly fill in your normal range (BPM).',
                    medicationType:
                        'What are the medications you are currently taking?',
                    medicationName:
                        'Kindly list the names of the medicines or types of medicines you use.',
                    docName:
                        'Please specify the name of the hospital and doctor taking care of your conditions.',
                    allergy: 'Do you have any allergies related to medicine?',
                    allergyName:
                        'Kindly list the names of the medicines or types of medicines you are allergic to.',
                },
                q3: {
                    preferredDoc:
                        'Do you have any preferred doctors who you visit when required?',
                    mentionDoc:
                        'If you do have preferred doctors, please mention their names and their fields of expertise below. Ex: Dr. Saehej (Bone specialist)',
                    docName: "Doctor's name",
                    docField: 'Fields of expertise',
                    allergy: 'Do you have any allergies related to food?',
                    listAllergies: 'Kindly list your food allergies.',
                    therapy: 'Have you been taking any therapy lately?',
                    listTherapy: 'Please specify your recent therapy.',
                    insurance: 'Do you have any Health insurance? ',
                    listInsurance:
                        'Which health insurance company are you a client of?',
                    insuranceRef: 'Please specify your insurance ref/ID.',
                },
            },
        },
        notFound: 'Not found data',
    },
    th: {
        translation: {
            header: {
                home: 'หน้าหลัก',
                track: 'การติดตาม',
                makeApp: 'สร้างการนัดหมาย',
                treatment: 'บันทึกการรักษา',
                hospAndClin: 'โรงพยาบาล และคลินิก',
                graph: 'กราฟ',
                about: 'เกี่ยวกับพวกเรา',
                newsEvent: 'ข่าวสาร และกิจกรรม',
                noti: 'การแจ้งเตือน',
                view: 'ดูการแจ้งเตือนทั้งหมด',
                viewP: 'ดูโปรไฟล์',
                sign: 'ออกจากระบบ',
            },
            footer: {
                cate: 'หมวดหมู่',
                cont: 'ช่องทางการติดต่อ',
                get: 'GET THE APP',
                track: 'การติดตาม',
                makeApp: 'สร้างการนัดหมาย',
                hospAndClin: 'โรงพยาบาล และคลินิก',
                graph: 'กราฟ',
                about: 'เกี่ยวกับพวกเรา',
                home: 'หน้าหลัก',
            },
            home: {
                techCare1: 'สวัสดี',
                techCare2: 'always by your side',
                techCare3:
                    'Managing your health and access to care have never been this good, this easy and this fast',
                ourSer: 'Our Service',
                partner: 'Partner',
                over: 'ภาพรวม',
                blood: 'Blood Pressure',
                bloodDesc:
                    'Measurements analysis, statistics, graph to analyse high or low blood pressure!',
                battery: 'Battery',
                heart: 'Heart Rate',
                heartDesc: 'you can measure and monitor your heart rate!',
                step: 'Step Count',
                stepDesc:
                    'Measurements analysis, statistics, graph to analyse high or low blood pressure!',
                temp: 'Temperature',
                tempDesc:
                    'Measurements analysis, statistics, graph to analyse high or low blood pressure!',
                spo2: 'SPO2',
                spo2Desc: 'you can measure and monitor your heart rate!',
                see: 'เพิ่มเติม',
                near: 'โรงพยาบาล และคลินิกที่ใกล้ที่สุด',
                make: 'สร้างการนัดหมาย',
                find: 'หาโรงพยาบาล และคลินิก',
                findNear: 'หาโรงพยาบาล และคลินิกที่ใกล้ที่สุด',
                search: 'ค้นหา',
            },
            profile: {
                emailNoti: 'การแจ้งเตือนอีเมล',
                pushNoti: 'การแจ้งเตือน',
                language: 'ภาษา',
                logOut: 'ออกจากระบบ',
                sex: 'เพศ',
                age: 'อายุ',
                blood: 'หมู่เลือด',
                weight: 'น้ำหนัก',
                height: 'ส่วนสูง',
                diagnostic: 'Diagnostic',
                editProfileBtn: 'แก้ไขโปรไฟล์',
                editQuestionnaire: 'แก้ไขแบบสอบถาม',
                back: 'กลับ',
                editProfile: {
                    changeProfile: 'เปลี่ยนรูปโปรไฟล์',
                    close: 'ปิด',
                    saveChange: 'บันทึกการเปลี่ยนแปลง',
                    firstName: 'ชื่อ',
                    lastName: 'นามสกุล',
                    sex: 'เพศ',
                    dob: 'วันเกิด',
                    email: 'อีเมล',
                    contact: 'หมายเลขติดต่อ',
                    city: 'เมือง/จังหวัด',
                    state: 'ประเทศ',
                    address: 'ที่อยู่',
                    submit: 'ส่ง',
                    changePasswordBtn: 'เปลี่ยนรหัสผ่าน',
                    changePassword: {
                        password: 'รหัสผ่าน',
                        new: 'รหัสผ่านใหม่',
                        confirm: 'ยืนยันรหัสผ่านใหม่',
                        confirmBtn: 'ยืนยัน',
                    },
                },
            },
            track: {
                track: 'การติดตาม',
                trackMy: 'การติดตามไม้เท้าของฉัน',
                latest: 'ล่าสุด',
                home: 'บ้าน',
                emer: 'Emergency',
                record: 'Hospital/Clinic Record',
                hosp: 'โรงพยาบาล',
                clin: 'คลินิก',
                notFound: 'ไม่พบข้อมูลที่ท่านต้องการ',
                today: 'ตารางวันนี้',
                schedule: 'ตารางนัดหมาย',
            },
            makeApp: {
                please: 'เลือกอาการของคุณ',
                appointment: 'การนัดหมาย',
                available: 'Available Time',
                make: 'สร้างการนัดหมาย',
                back: 'กลับ',
                detail: 'รายละเอียด',
                date: 'วันที่',
                time: 'เวลา',
                cancel: 'ยกเลิก',
                confirm: 'ยืนยันการนัดหมาย',
            },
            graph: {
                graph: 'กราฟ',
                blood: 'Blood Pressure',
                heart: 'Heart Rate',
                step: 'Step Count',
                temp: 'Temperature',
                spo2: 'SPO2',
                hour: 'ชั่วโมง',
                day: 'วัน',
                week: 'สัปดาห์',
                month: 'เดือน',
                year: 'ปี',
                all: 'ทั้งหมด',
                to: 'ถึง',
            },
            hospAndClin: {
                enter: 'ใส่ชื่อโรงพยาบาล หรือคลินิก',
                please: 'กรุณาเลือก',
                hops: 'โรงพยาบาล',
                clin: 'คลินิก',
                make: 'สร้างการนัดหมาย',
            },
            about: {
                ourService: 'Our Service',
            },
            questionnaire: {
                questionnaire: 'แบบสอบถาม',
                prev: 'ย้อนกลับ',
                fin: 'ส่ง',
                next: 'หน้าต่อไป',
                save: 'กำลังบันทึก...',
                submit: 'กำลังส่ง...',
                yes: 'ใช่',
                no: 'ไม่',
                q1: {
                    weight: 'น้ำหนัก (กก.)',
                    height: 'ส่วนสูง (ซม.)',
                    bloodGroup: 'หมู่เลือด',
                    rhtype: 'Rh type',
                    caretaker: 'กรุณาระบุชื่อผู้ดูแลสำหรับการติดต่อ',
                    emergencyContact:
                        'กรุณาระบุชื่อผู้ที่สามารถติดต่อได้ในภาวะฉุกเฉิน',
                    firstName: 'ชื่อจริง',
                    lastName: 'นามสกุล',
                    email: 'อีเมล',
                    number: 'เบอร์โทรศัพท์มือถือ',
                },
                q2: {
                    mostVisitedHospital:
                        'กรุณาระบุโรงพยาบาลที่ท่านใช้บริการบ่อยที่สุด',
                    no1: 'ลำดับที่ 1',
                    no2: 'ลำดับที่ 2',
                    other: 'อื่นๆ (โปรดระบุ)',
                    memberNum: 'หมายเลขบัตรสมาชิกโรงพยาบาล (ถ้ามี)',
                    disease: 'ท่านมีภาวะทางสุขภาพหรือการเจ็บป่วยหรือไม่?',
                    listDisease: 'กรุณาระบุชื่อโรคและ/หรืออาการ',
                    bloodPressure:
                        'หากท่านมีปัญหาเกี่ยวกับโรคหัวใจหรือความดันโลหิต กรุณากรอกค่าความดันโลหิต (mmHg)',
                    from: 'จาก',
                    to: 'ถึง',
                    bpm: 'หากท่านมีปัญหาเกี่ยวกับโรคหัวใจหรือปัญหาอัตราการเต้นหัวใจ กรุณากรอกอัตราการเต้นหัวใจ (BPM)',
                    medicationType: 'ปัจจุบันท่านใช้ยาอะไรอยู่?',
                    medicationName: 'กรุณาระบุชื่อยาหรือประเภทของยาที่ใช้',
                    docName:
                        'กรุณาระบุชื่อโรงพยาบาลและแพทย์ที่ดูแลอาการป่วยของท่าน',
                    allergy: 'ท่านมีอาการแพ้ยาหรือไม่?',
                    allergyName:
                        'กรุณาระบุชื่อยาหรือประเภทของยาที่ท่านมีอาการแพ้',
                },
                q3: {
                    preferredDoc:
                        'ท่านมีแพทย์ที่ใช้บริการประจำเมื่อจำเป็นหรือไม่?',
                    mentionDoc:
                        'หากมี โปรดระบุชื่อและสาขาความเชี่ยวชาญของแพทย์ ตัวอย่าง: Dr. นพ.สมชาย (ผู้เชี่ยวชาญด้านกระดูก)',
                    docName: 'ชื่อแพทย์',
                    docField: 'สาขาความเชี่ยวชาญ',
                    allergy: 'ท่านมีอาการแพ้อาหารหรือไม่?',
                    listAllergies: 'กรุณาระบุอาหารที่ท่านมีอาการแพ้',
                    therapy: 'ท่านได้เข้ารับการรักษาใดเมื่อเร็วๆ นี้หรือไม่?',
                    listTherapy: 'โปรดระบุการรักษาล่าสุดของท่าน',
                    insurance: 'ท่านมีประกันสุขภาพหรือไม่?',
                    listInsurance: 'ท่านเป็นลูกค้าของบริษัทประกันสุขภาพใด?',
                    insuranceRef: 'โปรดระบุหมายเลขอ้างอิง/รหัสประกันของท่าน',
                },
            },
        },
        notFound: 'ไม่พบข้อมูล',
    },
};

export default resources;
