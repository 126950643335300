import { Bell } from 'react-feather';
import { DropdownToggle, Dropdown, Media, Anchor } from '@doar/components';
import AuthService from '../../../services/auth.service';
import {
    StyledDropMenu,
    StyledDropHeader,
    StyledDropItem,
    StyledBadge,
} from '../header-dropdown-elements';
import { JSXElementConstructor, useEffect, useRef, useState } from 'react';
import { StyledMediaBody, StyledSpan, StyledStrong, StyledText } from './style';
import ReactDOMServer from 'react-dom/server';
import { v4 as uuidv4 } from 'uuid';
import { API_REALTIME_URL } from '../../../constants/api';

interface IProps {
    title: string;
    detail: string;
    ref: string;
    time: string;
}

const NotificationDropdown = () => {
    const dropRef = useRef<HTMLDivElement | null>(null);
    const [notifications, setNotifications] = useState([] as IProps[]);
    const NotiComponent = () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return (
            <>
                {notifications.length > 0 &&
                    notifications.map(
                        ({ title, detail, ref, time }: IProps) => {
                            return (
                                <Anchor path={ref} key={`${uuidv4()}`}>
                                    <Media>
                                        <StyledMediaBody>
                                            <StyledStrong>{title}</StyledStrong>
                                            <StyledText>{detail}</StyledText>
                                            <StyledText>ref: {ref}</StyledText>
                                            <StyledSpan>{time}</StyledSpan>
                                        </StyledMediaBody>
                                    </Media>
                                </Anchor>
                            );
                        }
                    )}
            </>
        );
    };

    useEffect(() => {
        const fetchDataLongPolling = async () => {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${AuthService.getJWT()}`,
                },
            };
            const response = await fetch(
                `${API_REALTIME_URL}notifications`,
                options
            );
            if (response.ok) {
                const message = await response.json();
                const newData = message.data;
                // console.log(newData);
                notifications.push(...(newData as IProps[]));
                // console.log(notifications);
                if (dropRef.current) {
                    if (notifications.length != 0) {
                        // console.log('here');
                        dropRef.current.innerHTML =
                            ReactDOMServer.renderToString(<NotiComponent />);
                    }
                }
            }
            await fetchDataLongPolling();
        };
        const fetchData = async () => {
            const response = await AuthService.getNotifications();
            notifications.push(...(response as IProps[]));
            // setNotifications(response as Array<IProps>);
            if (notifications.length != 0) {
                if (dropRef.current) {
                    dropRef.current.innerHTML = ReactDOMServer.renderToString(
                        <NotiComponent />
                    );
                }
            }
            // console.log(notifications);
            await fetchDataLongPolling();
        };
        void fetchData();
    }, []);

    return (
        <Dropdown direction="down">
            <DropdownToggle variant="texted">
                <Bell className="header-icon" />
                {notifications.length != 0 && (
                    <StyledBadge>{notifications.length}</StyledBadge>
                )}
            </DropdownToggle>
            <StyledDropMenu>
                <StyledDropHeader>NOTIFICATIONS</StyledDropHeader>
                <div ref={dropRef}>
                    <StyledDropItem path="">
                        Notification not found
                    </StyledDropItem>
                </div>

                {/* <StyledDropFooter>
                    <Link to="/profile-view">VIEW ALL NOTIFICATIONS</Link>
                </StyledDropFooter> */}
            </StyledDropMenu>
        </Dropdown>
    );
};

export default NotificationDropdown;
