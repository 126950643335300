import { StyledLogo } from './style';
import LogoImage from '@doar/shared/images/logo/Home Logo.png';

const Logo = () => {
    return (
        <StyledLogo path="/home">
            <img src={LogoImage} alt="logo-img" height={'50pc'} />
        </StyledLogo>
    );
};

export default Logo;
