import {
    Media,
    MediaBody,
    CardTitle,
    Button,
    Text,
    TabWrap,
    TabList,
    Tab,
    TabContent,
    TabPanel,
} from '@doar/components';
import { Plus } from 'react-feather';
import AppointmentList from '../../../components/appointment-list';
import AuthService from '../../../services/auth.service';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/loading/Loading';

const Row2 = () => {
    const { t } = useTranslation();
    const [resHosp, setResHosp] = useState([] as any[]);
    const [resClin, setResClin] = useState([] as any[]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await AuthService.getMedicalFacilityRecordHome();
            setResHosp(response[0]);
            setResClin(response[1]);
            setLoading(false);
        };

        void fetchData();
    }, []);
    return (
        <Media display="block">
            <Media display="flex">
                <MediaBody>
                    <CardTitle>{t('track.record')}</CardTitle>
                </MediaBody>
                {/* <Button
                    shape="ellipse"
                    variant="outlined"
                    color="secondary"
                    iconButton
                    path="/make-appointment"
                >
                    <Plus />
                </Button> */}
            </Media>
            <TabWrap justified>
                <TabList>
                    <Tab>{t('track.hosp')}</Tab>
                    <Tab>{t('track.clin')}</Tab>
                </TabList>
                <TabContent>
                    <TabPanel>
                        {loading ? (
                            <Loading loading={loading} />
                        ) : (
                            <>
                                {resHosp.length == 0 && (
                                    <Text>{t('track.notFound')}</Text>
                                )}
                                <AppointmentList listItems={resHosp} />
                            </>
                        )}
                    </TabPanel>
                    <TabPanel>
                        {loading ? (
                            <Loading loading={loading} />
                        ) : (
                            <>
                                {resClin.length == 0 && (
                                    <Text>{t('track.notFound')}</Text>
                                )}
                                <AppointmentList listItems={resClin} />
                            </>
                        )}
                    </TabPanel>
                </TabContent>
            </TabWrap>
        </Media>
    );
};

export default Row2;
