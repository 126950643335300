import Row2 from '../containers/track/Row2';
import SEO from '../components/seo';
import Layout from '../layouts';
import Content from '../layouts/content';
import AuthService from '../services/auth.service';
import { Navigate } from 'react-router-dom';

const ProfileView = () => {
    const isLogin = AuthService.isLogin();
    const doneQuestionnaire = AuthService.getQuestionnaireStatus();
    // if user not loggin
    if (isLogin == false) {
        return <Navigate to={'/login'} />;
    } else if (doneQuestionnaire == false) {
        return <Navigate to={'/questionnaire'} />;
    }
    return (
        <Layout>
            <SEO />
            <Content minHeight={['calc(100vh - 300px)']}>
                <Row2 />
            </Content>
        </Layout>
    );
};

export default ProfileView;
