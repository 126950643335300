import resources from '@doar/shared/Text/langData';
import i18n from 'i18next';
import { Suspense, lazy, useEffect, useState } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import CookieConsentPopup from './components/cookie-consent/CookieConsentPopup';
import Preloader from './components/preloader';
import { FirebaseProvider } from './context/firebaseContext';
import './global.css';
import NewsAndEvent from './pages/news-and-event';
import TreatmentRecords from './pages/treatment-records';
import AuthService from './services/auth.service';
// Classic Pages

const AboutUs = lazy(() => import('./pages/aboutus'));
const Default = lazy(() => import('./pages/default'));
const Home = lazy(() => import('./pages/home'));
const ProfilePrev = lazy(() => import('./pages/profile-prev'));
const Profile = lazy(() => import('./pages/profile'));
const Questionnaire = lazy(() => import('./pages/questionnaire'));
const Record = lazy(() => import('./pages/record'));
const Emergency = lazy(() => import('./pages/emergency'));
const MakeAppointments = lazy(() => import('./pages/make-appointment'));
const Appointments = lazy(() => import('./pages/appointment'));
const Track = lazy(() => import('./pages/track'));
const HCList = lazy(() => import('./pages/hosp-clin-list'));
const Graph = lazy(() => import('./pages/graph'));
const ProfileAdmin = lazy(() => import('./pages/profile-admin'));
const SignIn = lazy(() => import('./pages/signin'));
const SignUp = lazy(() => import('./pages/signup'));
const VerifyAccount = lazy(() => import('./pages/verify-account'));
const ForgotPassword = lazy(() => import('./pages/forgot-password'));
const ErrorNotFound = lazy(() => import('./pages/error-404'));
const ClassicPlusErrorNotFound = lazy(
    () => import('./pages/classic-plus/error-404')
);

void i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // lng: 'th',
        lng: 'en',
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

const App = () => {
    // const [isLogin, setIsLogin] = useState(AuthService.isLogin());
    // useEffect(() => {
    //     const fetchData = () => {
    //         setIsLogin(AuthService.isLogin());
    //         // console.log('start');
    //         // const response = await AuthService.getEmergencyAlert();
    //         // console.log(response);
    //         // console.log('end');
    //     };
    //     void fetchData();
    // }, [isLogin]);

    // console.error = () => {};
    useEffect(() => {
        const setLanguage = async () => {
            const savedLang = JSON.parse(await AuthService.getSetting())
                .lang as string;
            void i18n.changeLanguage(savedLang || 'en');
        };
        void setLanguage();
    }, []);

    return (
        <I18nextProvider i18n={i18n}>
            <Router>
                <FirebaseProvider>
                    <Suspense fallback={<Preloader />}>
                        <Routes>
                            {/* <Route path="*" element={<ErrorNotFound />} /> */}
                            <Route path="/" element={<Default />} />

                            <Route path="/signin" element={<SignIn />} />
                            <Route path="/signup" element={<SignUp />} />
                            <Route path="*" element={<SignIn />} />

                            <Route path="*" element={<Default />} />
                            <Route path="/home" element={<Home />} />
                            <Route
                                path="/profile-prev"
                                element={<ProfilePrev />}
                            />
                            <Route path="/profile" element={<Profile />} />
                            <Route
                                path="/edit-profile"
                                element={<Profile defaultDisplay={false} />}
                            />
                            <Route
                                path="/my-appointments/:pName/:pID"
                                element={<Record />}
                            />
                            <Route
                                path="/make-appointment"
                                element={<MakeAppointments />}
                            />
                            <Route
                                path="/appointment"
                                element={<Appointments />}
                            />
                            <Route
                                path="/treatment-records"
                                element={<TreatmentRecords />}
                            />
                            <Route path="/track" element={<Track />} />
                            <Route
                                path="/hospitals-clinics-list"
                                element={<HCList />}
                            />
                            <Route path="/graph/:tab" element={<Graph />} />
                            <Route path="/graph" element={<Graph />} />
                            <Route
                                path="/news-and-event"
                                element={<NewsAndEvent />}
                            />
                            <Route
                                path="/verify-account"
                                element={<VerifyAccount />}
                            />
                            <Route
                                path="/forgot-password"
                                element={<ForgotPassword />}
                            />
                            <Route
                                path="/questionnaire"
                                element={<Questionnaire />}
                            />
                            <Route
                                path="/admin-profile"
                                element={<ProfileAdmin />}
                            />
                            <Route path="/emergency" element={<Emergency />} />
                        </Routes>
                    </Suspense>
                </FirebaseProvider>
            </Router>
            <CookieConsentPopup />
        </I18nextProvider>
    );
};

export default App;
