/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, useEffect } from 'react';
import { Menu, X, ArrowLeft } from 'react-feather';
import { Navbar, Button } from '@doar/components';
import Logo from '../../components/logo';
import NotificationDropdown from '../../components/header/notification-dropdown';
import ProfileDropdown from '../../components/header/profile-dropdown';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { toggleSidebar, toggleBody } from '../../redux/slices/ui';
import AuthService from '../../services/auth.service';
import UserService from '../../services/user.service';
import Englishtext from '@doar/shared/Text/header/en.json';
import Thaitext from '@doar/shared/Text/header/th.json';
import {
    StyledHeader,
    StyledLogo,
    StyledNavbarWrap,
    StyledNavbarMenu,
    StyleNavbarRight,
    StyledNavbarElement,
    StyledNavbarHeader,
    StyledNavbarBody,
    StyledMenuBtn,
    StyledSidebarBtn,
} from './style';
import { useTranslation } from 'react-i18next';

interface IProps {
    hasSidebar?: boolean;
    sidebarLayout?: 1 | 2;
}

const Header = ({ hasSidebar, sidebarLayout }: IProps) => {
    const { t, i18n } = useTranslation();
    const isLogin = AuthService.isLogin();
    const dispatch = useAppDispatch();
    const { sidebar, isBody } = useAppSelector((state) => state.ui);

    const [menuOpen, setMenuOpen] = useState(false);
    const sidebarHandler = useCallback(
        (_: any, isOpen?: 'open') => {
            dispatch(toggleSidebar({ isOpen }));
        },
        [dispatch]
    );

    const bodyHandler = useCallback(() => {
        dispatch(toggleBody());
        dispatch(toggleSidebar({ isOpen: 'open' }));
    }, [dispatch]);

    const menuHandler = useCallback(() => {
        setMenuOpen((prev) => !prev);
        if (menuOpen) {
            sidebarHandler(undefined, 'open');
        }
    }, [menuOpen, sidebarHandler]);

    useEffect(() => {
        return () => {
            sidebarHandler(undefined, 'open');
            bodyHandler();
        };
    }, [sidebarHandler, bodyHandler]);

    const currentLang = () => {
        if (UserService.getCurrentLang() == 'th') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return Thaitext;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return Englishtext;
    };

    const myMenu = [
        { id: 0, label: t('header.home'), url: '/home' },
        { id: 1, label: t('header.graph'), url: '/graph' },
        { id: 2, label: t('header.track'), url: '/track' },
        {
            id: 3,
            label: t('header.hospAndClin'),
            url: '/hospitals-clinics-list',
        },
        {
            id: 4,
            label: t('header.makeApp'),
            url: '/appointment',
        },
        {
            id: 5,
            label: t('header.treatment'),
            url: '/treatment-records',
        },
        { id: 6, label: t('header.about'), url: '/' },
        { id: 7, label: t('header.newsEvent'), url: '/news-and-event' },
    ];

    return (
        <>
            <StyledHeader>
                {hasSidebar && sidebarLayout === 1 && (
                    <>
                        <StyledMenuBtn
                            variant="texted"
                            onClick={menuHandler}
                            $hasSidebar={hasSidebar}
                            $sidebarOpen={sidebar}
                            $bodyOpen={isBody}
                            className="menu-btn"
                        >
                            <Menu size={20} strokeWidth="2.5px" />
                        </StyledMenuBtn>
                        <StyledSidebarBtn
                            variant="texted"
                            onClick={!isBody ? sidebarHandler : bodyHandler}
                            $sidebarOpen={sidebar}
                            $bodyOpen={isBody}
                            className="sidebar-btn"
                        >
                            <ArrowLeft size={20} strokeWidth="2.5px" />
                        </StyledSidebarBtn>
                    </>
                )}
                {hasSidebar && sidebarLayout === 2 && (
                    <>
                        <StyledMenuBtn
                            variant="texted"
                            onClick={menuHandler}
                            $hasSidebar={hasSidebar}
                            $sidebarOpen={!sidebar}
                        >
                            <Menu size={20} strokeWidth="2.5px" />
                        </StyledMenuBtn>
                        <StyledSidebarBtn
                            variant="texted"
                            onClick={sidebarHandler}
                            $sidebarOpen={!sidebar}
                        >
                            <ArrowLeft size={20} strokeWidth="2.5px" />
                        </StyledSidebarBtn>
                    </>
                )}
                {!hasSidebar && (
                    <StyledMenuBtn
                        variant="texted"
                        onClick={menuHandler}
                        $hasSidebar={hasSidebar}
                        $sidebarOpen={!sidebar}
                    >
                        <Menu size={20} strokeWidth="2.5px" />
                    </StyledMenuBtn>
                )}
                <StyledLogo>
                    {/* This is Logo */}
                    <Logo />
                </StyledLogo>
                <StyledNavbarWrap
                    $isOpen={menuOpen}
                    onClick={menuHandler}
                    className="navbar-wrap"
                >
                    <StyledNavbarMenu
                        $isOpen={menuOpen}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <StyledNavbarHeader>
                            {/* Logo in Navbar */}
                            <Logo />
                            <Button variant="texted" onClick={menuHandler}>
                                <X
                                    color="#7987a1"
                                    width={20}
                                    strokeWidth="2.5px"
                                />
                            </Button>
                        </StyledNavbarHeader>
                        <StyledNavbarBody>
                            {/* <StyledNavbarTitle>
                                MAIN NAVIGATION
                            </StyledNavbarTitle> */}
                            <Navbar
                                menus={
                                    isLogin
                                        ? myMenu
                                        : myMenu.filter((menu) =>
                                              [6, 7].includes(menu?.id)
                                          )
                                }
                            />
                        </StyledNavbarBody>
                    </StyledNavbarMenu>
                </StyledNavbarWrap>
                <StyleNavbarRight>
                    <StyledNavbarElement ml={['8px', '15px']}>
                        <NotificationDropdown />
                    </StyledNavbarElement>
                    <StyledNavbarElement ml={['15px', '20px', '30px']}>
                        {isLogin && <ProfileDropdown />}
                        {isLogin == false && (
                            <Button
                                color="primary"
                                shape="ellipse"
                                path="/signin"
                            >
                                {currentLang().signin}
                            </Button>
                        )}
                    </StyledNavbarElement>
                </StyleNavbarRight>
            </StyledHeader>
        </>
    );
};

Header.defaultProps = {
    sidebarLayout: 1,
};

export default Header;
