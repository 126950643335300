import styled, { css, themeGet } from '@doar/shared/styled';
import { Avatar, AvatarInitial, Card, Image } from '@doar/components';

export const StyledWarp = styled.div`
    width: 100%;
`;

export const StyledImage = styled(({ ...rest }) => <Image {...rest} />)`
    object-fit: cover;
    border-radius: 20px;
    margin: auto;
`;

export const StyledNonImage = styled(({ ...rest }) => (
    <AvatarInitial {...rest} />
))`
    object-fit: cover;
    border-radius: 20px;
    width: 500px;
    height: 300px;
`;

export const StyledMain = styled.div`
    background-color: #fff;
    width: 75rem;
    transition: all 0.3s;
    ${(props) =>
        props.theme.name === 'dark' &&
        css`
            background-color: ${themeGet('colors.darkdarken5')};
        `}
`;

export const StyledBody = styled.div`
    position: absolute;
    width: 100%;
`;

export const StyledLocate = styled.div`
    font-size: 16px;
    display: flex;
    font-family: ${themeGet('fonts.interUi')};
    left: 0;
`;

export const StyledDate = styled.div`
    font-size: 16px;
    display: flex;
    font-family: ${themeGet('fonts.interUi')};
    margin: auto;
    right: 0;
`;

export const StyledOptionsBtn = styled.button`
    border: none;
    background-color: transparent;
    margin-left: auto;
    color: ${themeGet('colors.gray600')};
    &:hover,
    &:focus {
        color: ${themeGet('colors.cornflower')};
    }
`;

export const StyledBtn = styled.div`
    padding: 7px;
`;

export const StyledTitle = styled.div`
    possition: absolute;
    display: flex;
    font-size: 18px;
    font-weight: bold;
`;

export const StyledDsc = styled.div`
    font-size: 16px;
    color: ${themeGet('colors.text2')};
    word-wrap: break-word;
`;

export const StyledResp = styled.div`
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    height: 100%;
`;

export const StyledCont = styled.div`
    margin-bottom: 10px;
    height: 100%;
`;
