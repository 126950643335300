import { Media, MediaBody, Button } from '@doar/components';
import {
    StyledBtn,
    StyledWarp,
    StyledTitle,
    StyledDsc,
    StyledResp,
    StyledCont,
    StyledImage,
} from './style';
import { Plus } from 'react-feather';

interface DProps {
    pImage: string;
    pName: string;
    pDetail: string;
    pPhone: string;
    pType: string;
}

interface IProps {
    listItems: Array<DProps>;
}

const DisplayList = ({ pImage, pName, pDetail, pPhone, pType }: DProps) => {
    return (
        <StyledCont>
            <StyledBtn>
                <Media
                    display={['block', null, null, 'flex']}
                    height={'100%s'}
                    margin={[0, '20px']}
                >
                    <MediaBody margin="auto">
                        <StyledImage
                            alt={pName}
                            src={pImage}
                            width={'500px'}
                            height={'300px'}
                        />
                    </MediaBody>
                    <MediaBody
                        ml={[0, 0, 0, '20px']}
                        mt={['40px', null, null, 0]}
                        px={[null, null, null, '10px']}
                    >
                        <StyledTitle>{pName}</StyledTitle>
                        <StyledDsc>{pDetail}</StyledDsc>
                        <StyledResp>
                            <br />
                            {pPhone.split('<br />')[0]}
                            <br />
                            {pPhone.split('<br />')[1]}
                        </StyledResp>
                        <Media height="100%" mt="auto" mb="0px">
                            <Button
                                path={`/my-appointments/${pName}/${pType}`}
                                iconButton
                                mt={'20px'}
                                size="md"
                                shape="ellipse"
                                padding={'10px'}
                                className="btn-custom"
                                width={'130px'}
                            >
                                <Plus />
                                <b>See More</b>
                            </Button>
                        </Media>
                    </MediaBody>
                </Media>
            </StyledBtn>
        </StyledCont>
    );
};

const Subplace = ({ listItems }: IProps) => {
    return (
        <StyledWarp>
            {listItems?.map(
                ({ pImage, pName, pDetail, pPhone, pType }: DProps) => {
                    return (
                        <DisplayList
                            pImage={pImage}
                            pName={pName}
                            pDetail={pDetail}
                            pPhone={pPhone}
                            pType={pType}
                            key={`${pName}at${pPhone}`}
                        />
                    );
                }
            )}
        </StyledWarp>
    );
};

export default Subplace;
