import { css } from 'styled-components';
import sfThonburiBoldttf from './sf-thonburi/SFThonburi-Bold.ttf';
import sfThonburiRegularttf from './sf-thonburi/SFThonburi-Regular.ttf';
import sfThonburiSemiBoldttf from './sf-thonburi/SFThonburi-Semibold.ttf';

export const SFThonburiFont = css`
    @font-face {
        font-family: 'SF Thonburi';
        src: url(${sfThonburiBoldttf});
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'SF Thonburi';
        src: url(${sfThonburiRegularttf});
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'SF Thonburi';
        src: url(${sfThonburiSemiBoldttf});
        font-weight: 600;
        font-style: normal;
    }
`;
