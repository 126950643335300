import React from 'react';

import ClipLoader from 'react-spinners/ClipLoader';

export interface LoadingProps {
    loading: boolean;
}

const Loading: React.FC<LoadingProps> = ({ loading }) => {
    return (
        <div className="loading">
            <ClipLoader
                color="#36d7b7"
                cssOverride={{}}
                size={50}
                loading={loading}
            />
        </div>
    );
};

export default Loading;
