/* eslint-disable react/react-in-jsx-scope */
import styled, { themeGet, device, tinycolor, css } from '@doar/shared';
import { Anchor } from '@doar/components';

export const StyledFooter = styled.footer`
    font-size: 10px;
    font-family: ${themeGet('fonts.interUi')};
    letter-spacing: 0.3px;
    padding: 30px 25px;
    background-color: black;
    color: white;
    border-top: 1px solid ${themeGet('colors.border')};
    text-transform: uppercase;
    max-width: 1150px;
    margin: 0 auto;
    ${device.large} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
    }
    ${(props) =>
        props.theme.name === 'cool' &&
        css`
            border-top-color: transparent;
            box-shadow: 0 0 25px
                ${tinycolor(themeGet('colors.skinUi01')(props) as string)
                    .setAlpha(0.1)
                    .toRgbString()};
        `}
    ${(props) =>
        props.theme.name === 'dark' &&
        css`
            background-color: ${themeGet('colors.gray900')};
            border-top-width: 0;

            a {
                color: ${themeGet('colors.gray500')};
                &:hover,
                &:focus {
                    color: #fff;
                }
            }
        `}
`;

export const StyledFooterContainer = styled.div`
    background-color: black;
`;

export const StyledFooterLeft = styled.div`
    margin-top: 10px;
    ${device.medium} {
        margin-top: 0;
    }
    a {
        padding: 0;
        color: white;
    }
    .copright-link {
        display: inline-flex;
        a {
            margin-left: 20px;
        }
    }
`;

export const StyledFooterRight = styled.div`
    display: flex;
    flex-direction: row;
`;

export const StyledFooterNav = styled.nav`
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
`;

export const StyledFotterNavLink = styled(({ ...rest }) => (
    <Anchor {...rest} />
))`
    font-size: 12px;
    margin-bottom: 12px;
    padding: 0;
    color: white;
    display: flex;
    &:hover {
        color: #2ac2b2;
    }
`;

export const StyledFotterNavTitle = styled.div`
    padding: 0;
    color: white;
    display: block;
    margin-bottom: 20px;
    font-size: 16px;
`;

export const StyledFotterNavLinkList = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledFotterNavDesc = styled.div`
    padding: 0;
    color: white;
    display: flex;
    font-size: 12px;
    margin-bottom: 14px;
`;
