import Layout from '../layouts';
import Content from '../layouts/content';
import SEO from '../components/seo';
import { useEffect, useState } from 'react';
import AuthService from '../services/auth.service';
import SlideShow from '../containers/newsAndEvent/SlideShow';
import { WORDPRESS_URL } from 'src/constants/api';

const NewsAndEvent = () => {
    const wordpress = WORDPRESS_URL;
    window.location.href = wordpress + 'news-and-events/';
    return null;
    // const [listData, handleData] = useState([] as any);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         handleData(await AuthService.getAfterSalePromos());
    //     };

    //     void fetchData();
    // }, []);

    // return (
    //     <Layout>
    //         <SEO />
    //         <div className="border-card p-59">
    //             <>
    //                 <SlideShow listData={listData} />
    //             </>
    //         </div>

    //         {/* <Content>
    //         </Content> */}
    //     </Layout>
    // );
};

export default NewsAndEvent;
