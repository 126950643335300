// import FooterLogo from "@doar/shared/images/logo/Original on Transparent.png";
import FooterLogo from '@doar/shared/images/logo/Home Logo.png';
import {
    StyledFooter,
    StyledFooterContainer,
    StyledFooterLeft,
    StyledFooterRight,
    StyledFotterNavLink,
    StyledFotterNavTitle,
    StyledFotterNavLinkList,
    StyledFotterNavDesc,
} from './style';
import { Button, Col, Image, Row } from '@doar/components';
import { Facebook, Phone, Youtube } from 'react-feather';
import { useTranslation } from 'react-i18next';
const Footer = () => {
    const { t } = useTranslation();
    return (
        <StyledFooterContainer>
            <StyledFooter>
                <StyledFooterRight>
                    <Row>
                        <Col col>
                            <StyledFotterNavTitle>
                                {t('footer.cate')}
                            </StyledFotterNavTitle>
                            <StyledFotterNavLinkList>
                                <StyledFotterNavLink path="/home">
                                    {t('footer.home')}
                                </StyledFotterNavLink>
                                <StyledFotterNavLink path="/track">
                                    {t('footer.track')}
                                </StyledFotterNavLink>
                                <StyledFotterNavLink path="/make-appointment">
                                    {t('footer.makeApp')}
                                </StyledFotterNavLink>
                                <StyledFotterNavLink path="/hospital-record">
                                    {t('footer.hospAndClin')}
                                </StyledFotterNavLink>
                                <StyledFotterNavLink path="/graph">
                                    {t('footer.graph')}
                                </StyledFotterNavLink>
                                <StyledFotterNavLink path="/">
                                    {t('footer.about')}
                                </StyledFotterNavLink>
                            </StyledFotterNavLinkList>
                        </Col>
                        <Col col basis>
                            <StyledFotterNavTitle>
                                {t('footer.cont')}
                            </StyledFotterNavTitle>
                            <StyledFotterNavLinkList>
                                <StyledFotterNavDesc>
                                    Phone: +66 924095723
                                </StyledFotterNavDesc>
                                <StyledFotterNavDesc>
                                    support@techcaresystem.com
                                </StyledFotterNavDesc>
                                <StyledFotterNavDesc>
                                    Phone: +66 924095723
                                </StyledFotterNavDesc>
                            </StyledFotterNavLinkList>
                        </Col>
                        <Col col>
                            <StyledFotterNavTitle>
                                {t('footer.get')}
                            </StyledFotterNavTitle>
                            <StyledFotterNavLinkList>
                                <StyledFotterNavLink path="/">
                                    Tech care for Android
                                </StyledFotterNavLink>
                                <StyledFotterNavLink path="/track">
                                    Tech care for iOS
                                </StyledFotterNavLink>
                                <StyledFotterNavLink path="/hospital-record">
                                    Apple Store
                                </StyledFotterNavLink>
                                <StyledFotterNavLink path="/setting">
                                    Google Play
                                </StyledFotterNavLink>
                            </StyledFotterNavLinkList>
                        </Col>
                    </Row>
                </StyledFooterRight>
                <StyledFooterLeft>
                    <Col>
                        <Row>
                            <Image
                                src={FooterLogo}
                                alt="footer logo"
                                width={'200px'}
                                filter="white"
                            />
                        </Row>
                        <Row mt={'20px'}>
                            <Col col>
                                <Button
                                    iconButton
                                    variant="outlined"
                                    color="white"
                                    size="lg"
                                    path="/"
                                >
                                    <Youtube color="white" />
                                </Button>
                            </Col>
                            <Col col>
                                <Button
                                    iconButton
                                    variant="outlined"
                                    color="white"
                                    size="lg"
                                    path="/"
                                >
                                    <Facebook color="white" />
                                </Button>
                            </Col>
                            <Col col>
                                <Button
                                    iconButton
                                    variant="outlined"
                                    color="white"
                                    size="lg"
                                    path="/"
                                >
                                    <Phone color="white" />
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </StyledFooterLeft>
            </StyledFooter>
        </StyledFooterContainer>
    );
};

export default Footer;
