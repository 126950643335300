import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'http://localhost:8080/api/test/';

class UserService {
    setLang(nameLang: string) {
        localStorage.setItem('lang', nameLang);
    }

    getCurrentLang() {
        const currentLang = localStorage.getItem('lang');
        if (!currentLang) {
            this.setLang('en');
            return 'en';
        }

        return currentLang;
    }

    changeLang() {
        const currentLang = this.getCurrentLang();
        if (currentLang == 'en') {
            this.setLang('th');
        } else {
            this.setLang('en');
        }
    }

    getPublicContent() {
        return axios.get(API_URL + 'all');
    }

    getUserBoard() {
        return axios.get(API_URL + 'user', { headers: authHeader() });
    }

    getModeratorBoard() {
        return axios.get(API_URL + 'mod', { headers: authHeader() });
    }

    getAdminBoard() {
        return axios.get(API_URL + 'admin', { headers: authHeader() });
    }
}

export default new UserService();
