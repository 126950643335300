import React, {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import AuthService from '../../src/services/auth.service';

export interface FirebaseContextType {
    setIsRunFirebase: (isRun: boolean) => void;
    isRunFirebase: boolean;
}

const FirebaseContext = createContext<FirebaseContextType | undefined>(
    undefined
);

export const FirebaseProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation();
    const [isRunFirebase, setIsRunFirebase] = useState(false);
    const value: FirebaseContextType = {
        setIsRunFirebase,
        isRunFirebase,
    };

    useEffect(() => {
        const isLogin = AuthService.isLogin();
        if (isLogin) {
            if (!isRunFirebase) {
                void (async () => {
                    const token = await AuthService.requestForToken();
                    if (token) {
                        const connect =
                            await AuthService.checkConnectFirebaseApi(token);
                        if (connect?.status === 200) {
                            setIsRunFirebase(true);
                        }
                    }
                })();
            }
        } else {
            setIsRunFirebase(false);
        }
    }, [location.pathname, isRunFirebase]);

    return (
        <FirebaseContext.Provider value={value}>
            {children}
        </FirebaseContext.Provider>
    );
};

export const useAppFirebase = () => {
    const context = useContext(FirebaseContext);
    if (context === undefined) {
        throw new Error(
            'useAppFirebase must be used within a FirebaseProvider'
        );
    }
    return context;
};
