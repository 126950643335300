import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const CookieConsentPopup = () => {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const consent = Cookies.get('tech-care-system-consent');
        if (!consent) {
            setShowPopup(true);
        }
    }, []);

    const handleAccept = () => {
        Cookies.set('tech-care-system-consent', 'true', { expires: 365 }); // Set for 1 year
        setShowPopup(false);
    };

    const handleClose = () => {
        setShowPopup(false);
    };

    if (!showPopup) return null;

    return (
        <div className="cookie-consent-popup">
            <div className="icon-close" aria-hidden onClick={handleClose}>
                &#10006;
            </div>
            <p>
                We use cookies to improve your experience. By continuing to use
                this site, you accept our cookie policy.
            </p>
            <button onClick={handleAccept}>Accept</button>
        </div>
    );
};

export default CookieConsentPopup;
